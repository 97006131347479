import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Page404 from "../pages/auth/Page404";
import Page403 from "../pages/auth/Page403";
import Page500 from "../pages/auth/Page500";

import { FallbackProvider } from "./lazyWrappers/FallbackProvider";
import {
  RequireUserAuth,
  ViewerAuth,
  RequireLoggedOut,
} from "./PermissionWrappers";


const OrganizationBillingManagement = React.lazy(() => import("../pages/org/OrganizationBillingManagement"));
const OrganizationPaymentMethods = React.lazy(() => import("../pages/org/OrganizationPaymentMethod"));
const OrganizationTier = React.lazy(() => import("../pages/org/OrganizationTier"));
const OrganizationList = React.lazy(() => import("../pages/org/OrganizationList"));

// Layouts
const OnboardLayout = React.lazy(() => import("../layouts/OnboardLayout"));
const AuthLayout = React.lazy(() => import("../layouts/AuthLayout"));
const MapLayout = React.lazy(() => import("../layouts/MapLayout"));
const InformationLayout = React.lazy(
  () => import("../layouts/InformationLayout")
);

// Main views
const AdminIndex = React.lazy(() => import("../pages/admin/AdminDashboard"));
const AdminOrganization = React.lazy(() => import("../pages/admin/Organization"));
const UserEvents = React.lazy(() => import("../pages/admin/UserEvents"));
const DatasetList = React.lazy(() => import("../pages/admin/DatasetList"));
const DatasetDetail = React.lazy(() => import("../pages/admin/DatasetDetails"));
const WorkOrders = React.lazy(() => import("../pages/admin/WorkOrders"));
const AdminGeometryDatasets = React.lazy(() => import("../pages/admin/GeometryDatasets"));
const Subscriptions = React.lazy(() => import("../pages/admin/Subscriptions"));
const SubscriptionTiers = React.lazy(() => import("../pages/admin/SubscriptionTiers"));
const EngineStatus = React.lazy(() => import("../pages/admin/EngineStatus"));
const UserSettings = React.lazy(() => import("../pages/user/UserSettings"));
// TODO: Remove completely
// const OrgSettings = React.lazy(
//   () => import("../pages/org/OrganizationSettings")
// );
const OrgAccount = React.lazy(() => import("../pages/org/OrganizationAccount"));
const OrgUserMgmt = React.lazy(
  () => import("../pages/org/OrganizationUserManagement")
);
const ORGAPIKeys = React.lazy(() => import("../pages/org/OrganizationAPIKeys"));
const Usage = React.lazy(() => import("../pages/org/Usage"));

// Auth
const SignIn = React.lazy(() => import("../pages/auth/SignIn"));
const SignOut = React.lazy(() => import("../pages/auth/SignOut"));
const SignUp = React.lazy(() => import("../pages/auth/SignUp"));
const ForgotPassword = React.lazy(() => import("../pages/auth/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../pages/auth/ResetPassword"));
const EmailUnconfirmed = React.lazy(
  () => import("../pages/auth/EmailUnconfirmed")
);
const ConfirmEmail = React.lazy(() => import("../pages/auth/ConfirmEmail"));
const Onboard = React.lazy(() => import("../pages/auth/Onboard"));
const AcceptInvite = React.lazy(() => import("../pages/auth/AcceptInvite"));

// User
const NotImplementedYet = React.lazy(
  () => import("../pages/NotImplementedYet")
);
const UserAccount = React.lazy(() => import("../pages/user/UserAccount"));
const UserAccessibility = React.lazy(
  () => import("../pages/user/UserAccessibility")
);

function AppRouter() {

  return (
  <BrowserRouter>
    <FallbackProvider>
      <Routes>
        <Route path="*" element={<Navigate to="/auth/404" />} />
        <Route path="/auth">
          {/* Must Be Logged Out */}
          <Route
            element={
              <RequireLoggedOut>
                <AuthLayout intent="success" />
              </RequireLoggedOut>
            }
          >
            <Route path="sign-in" element={<SignIn />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
          </Route>
          {/* Must Be Logged In */}
          <Route
            element={
              <RequireUserAuth level="user">
                <AuthLayout intent="success" />
              </RequireUserAuth>
            }
          >
            <Route path="email-unconfirmed" element={<EmailUnconfirmed />} />
          </Route>
          <Route
            element={
              <RequireUserAuth level="user">
                <OnboardLayout intent="success" />
              </RequireUserAuth>
            }
          >
            <Route path="onboard" element={<Onboard />} />
          </Route>
          {/* Authentication Agnostic */}
          <Route element={<AuthLayout intent="success" />}>
            <Route path="confirm-email" element={<ConfirmEmail />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="accept-invite" element={<AcceptInvite />} />
            <Route path="sign-out" element={<SignOut />} />
          </Route>
          <Route element={<AuthLayout intent="error" />}>
            <Route path="403" element={<Page403 />} />
            <Route path="404" element={<Page404 />} />
            <Route path="500" element={<Page500 />} />
          </Route>
        </Route>
        <Route path="/">
          <Route index element={<Navigate to="project/" />} />
          <Route path="project">
            <Route
              index
              path=":projectId?"
              element={
                <RequireUserAuth level="user">
                  <MapLayout />
                </RequireUserAuth>
              }
            />
          </Route>
        </Route>
        <Route path="/viewer/:viewerKey">
          <Route index element={<ViewerAuth />} />
        </Route>
        <Route
          path="/datasets"
          element={
            <RequireUserAuth level="user">
              <InformationLayout target="settings" />
            </RequireUserAuth>
          }
        >
          <Route index element={<DatasetList />} />
          <Route path=":dataset" element={<DatasetDetail />} />
        </Route>
        <Route
          path="/user"
          element={
            <RequireUserAuth level="user">
              <InformationLayout target="settings" />
            </RequireUserAuth>
          }
        >
          <Route path="account" element={<UserAccount />} />
          <Route path="usage" element={<Usage scope="user" />} />
          <Route path="settings" element={<UserSettings />} />
          <Route path="accessibility" element={<UserAccessibility />} />
        </Route>
        <Route
          path="/org"
          element={
            <RequireUserAuth level="user">
              <InformationLayout target="settings" />
            </RequireUserAuth>
          }
        >
          <Route path="account" element={<OrgAccount />} />
          <Route path="people" element={<OrgUserMgmt mode="org" />} />
          <Route path="api" index element={<ORGAPIKeys />} />
          <Route path="usage" element={<Usage scope="org" />} />
          <Route path="plans" element={<OrganizationTier />} />
          <Route path="billing" element={<OrganizationBillingManagement/>} />
          <Route path="payment-methods" element={<OrganizationPaymentMethods />} />
        </Route>
        <Route
          path="/admin"
          element={
            <RequireUserAuth level="admin">
              <InformationLayout target="admin" />
            </RequireUserAuth>
          }
        >
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<AdminIndex />} />
          <Route path="people" element={<OrgUserMgmt mode="admin" />} />
          <Route path="organizations" element={<OrganizationList />} />
          <Route path="organizations/:org_id/" element={<AdminOrganization />} />
          <Route path="userevents" element={<UserEvents/>} />
          <Route path="datasets">
            <Route index element={<DatasetList />} />
            <Route path=":dataset" element={<DatasetDetail />} />
          </Route>
          <Route path="geometrydatasets" element={<AdminGeometryDatasets/>} />
          <Route path="workorders" element={<WorkOrders />} />
          <Route path="subscriptions" element={<Subscriptions />} />
          <Route path="tiers" element={<SubscriptionTiers />} />
          <Route path="engine" element={<EngineStatus />} />
        </Route>
      </Routes>
    </FallbackProvider>
  </BrowserRouter>
  );
}

export default AppRouter;
