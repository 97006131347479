import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";

import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { authAtom, userAtom, viewerAuthAtom } from "../state";
import AuthService from "../services/authService";
import { AuthState } from "../GEMSchema";
import { AxiosError, AxiosResponse } from "axios";
import { useCurrentAuth } from "../lib/authUtils";
import { RESET } from "jotai/utils";

import { useTranslation } from "react-i18next";

import LoadingPage from "../pages/LoadingPage";

const ViewerLayout = React.lazy(() => import("../layouts/ViewerLayout"));

export const RequireUserAuth = ({
  children,
  level,
}: {
  children: JSX.Element;
  level: "user" | "admin";
}): JSX.Element => {
  const setCurrentAuth = useCurrentAuth()[1];
  const [viewerAuth, setViewerAuth] = useAtom(viewerAuthAtom);
  const auth = useAtomValue(authAtom);
  const user = useAtomValue(userAtom);
  const location = useLocation();
  const { i18n } = useTranslation();

  // Set user language
  useEffect(() => {
    if (user?.language) {
      i18n.changeLanguage(user.language);
    }
  }, [i18n, user.language]);

  // Set current auth to userAuth if user came from a /viewer route
  useEffect(() => {
    if (!viewerAuth) {
      return;
    }
    setCurrentAuth("user");
    setViewerAuth(RESET);
  }, [viewerAuth, setCurrentAuth, setViewerAuth]);

  if (viewerAuth) {
    return <LoadingPage />;
  }
  // TODO: differentiate user and admin user.is_admin

  if ((level === "user" && auth.userid) || (level === "admin" && auth.userid)) {
    const path = location.pathname;
    if (path === "/auth/sign-out") {
      return children;
    }
    if (user && !user.is_email_active) {
      if (path === "/auth/email-unconfirmed") {
        return children;
      }
      return <Navigate to="/auth/email-unconfirmed" replace />;
    }
    // TODO: Right now onboarded means Signup Code, later it'll mean the tutorial
    if (user && !user.is_onboarded) {
      if (path === "/auth/onboard") {
        return children;
      }
      return <Navigate to="/auth/onboard" state={{ from: location }} replace />;
    }
    return children;
  } else if (level === "admin") {
    /* Not an admin! Note: This doesn't prevent users from casually flipping switches in
     * their browser to *see* the admin-only stuff; it just hides it. The real
     * authentication happens on the API side.
     */
    return <Navigate to="/auth/403" replace />;
  }
  return <Navigate to="/auth/sign-in" state={{ from: location }} replace />;
};

export const ViewerAuth = (): JSX.Element => {
  const setViewerAuth = useSetAtom(viewerAuthAtom);
  const setCurrentAuth = useCurrentAuth()[1];
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [viewerKey, setViewerKey] = useState<string | null>(null);
  const [viewedProjectId, setViewedProjectId] = useState<string | null>(null);
  const urlParams = useParams();

  function handleViewerAuthSuccess(data: any) {
    setErrorCode(null);
    setViewerAuth({
      token: data.access,
      refresh: data.refresh,
      userid: null,
      lastRefresh: null,
    } as AuthState);
    setCurrentAuth("viewer");
    setViewedProjectId(data.projectId);
  }

  useEffect(() => {
    const viewerKey = urlParams.viewerKey;
    if (!viewerKey || viewerKey === "") {
      return;
    }
    // TODO: Check is the current key is still valid
    setViewerKey(viewerKey);
    AuthService.getViewerToken(viewerKey).then(
      (res: AxiosResponse) => {
        const data = res.data;
        if ("access" in data) {
          handleViewerAuthSuccess(data);
        }
      },
      (errorResponse: AxiosError) => {
        const status = errorResponse.response?.status || null;
        setErrorCode(status);
      }
    );
  }, [urlParams.viewerKey]);

  return (
    <ViewerLayout
      projectId={viewedProjectId}
      viewerUrl={viewerKey}
      errorCode={errorCode}
      onAuthSuccess={handleViewerAuthSuccess}
    />
  );
};

export const RequireLoggedOut = ({ children }: { children: JSX.Element }) => {
  const auth = useAtomValue(authAtom);
  if (auth.userid) {
    return <Navigate to="/" replace />;
  }
  return children;
};
