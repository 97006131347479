import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Routes from "./routes/Routes";
import { StyledToaster } from "./components/Toasts";
import * as Sentry from "@sentry/browser";

import "./assets/scss/app.scss";
import ErrorBoundary from "./pages/ErrorBoundary";
import { useEffect } from "react";

import "./i18n";
import { SENTRY_DSN } from "./constants";

function App() {
  const queryclient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  // TODO: Short and easy hack to differentiate staging; use Helmet later
  useEffect(() => {
    if (process.env.REACT_APP_DEPLOYMENT_ENV === "staging") {
      document.title = "[STAGING] " + document.title;
    }
  }, []);

  return (
    <ErrorBoundary>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}
      >
        <QueryClientProvider client={queryclient}>
          <Routes />
          <StyledToaster />
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
}

if (SENTRY_DSN !== "") {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: "production",
  });
  console.log("Error reporting enabled.");
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
