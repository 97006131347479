export const LAYER_ROOT_ID = "layer-root-id";

export const THEMES = [
  { label: "Light", value: "light" },
  { label: "Dark", value: "dark" },
];

export const PROJECT_PERMISSIONS = [
  { label: "View", value: "view" },
  { label: "Edit", value: "edit" },
];

export const PROJECT_PERMISSIONS_ALL = [
  { label: "Restricted", value: "none" },
  ...PROJECT_PERMISSIONS,
];

export const RENDERERS = [
  { label: "Client (CPU)", value: "client-cpu" },
  { label: "Client (GPU)", value: "client-gpu" },
  { label: "Server", value: "server" },
];

export const WORK_ORDER_STATUS = [
  { value: "ANY", label: "Any" },
  { value: "REQUESTED", label: "Requested" },
  { value: "WORKING", label: "Working" },
  { value: "REJECTED", label: "Rejected" },
  { value: "COMPLETED", label: "Completed" },
  { value: "FAILED", label: "Failed" },
  { value: "DELETED", label: "Deleted" },
];

export const WORK_ORDER_TYPE = [
  { value: "ANY", label: "Any" },
  { value: "FETCH", label: "Fetch data" },
  { value: "PROCESS", label: "Process" },
  { value: "DELETE", label: "Delete" },
];

export const ecosophyColorPalette = [
  "#980000",
  "#ffa600",
  "#710060",
  "#055b00",
  "#fc7a22",
  "#003f5c",
  "#d61449",
  "#0085b4",
  "#920064",
  "#009177",
  "#f95d6a",
  "#466200",
  "#bdd101",
  "#7e2207",
  "#2500aa",
  "#5e9838",
  "#c80000",
  "#14cdc3",
  "#f6e66f",
  "#d83e00",
  "#0a002f",
  "#6fb400",
  "#d1822a",
  "#c94751",
];

export const MIN_PASSWORD_LENGTH = 7;

export const EARTHOS_API_URL = process.env.EARTHOS_API_URL || "https://api.earthos.ai";
export const EARTHOS_ENGINE_HOST = process.env.EARTHOS_ENGINE_HOST || "https://engine.earthos.ai";
export const EARTHOS_ENGINE_SUBDOMAINS = "abcd";
export const SENTRY_DSN = process.env.SENTRY_DSN || "https://2c66e8ca057f4c9e8325737013711a53@app.glitchtip.com/7577";

if (process.env.NODE_ENV === "development") {
  console.log("------------------------[ DEVELOPMENT MODE ]----------------------------------");
  console.log("EARTHOS_API_URL", EARTHOS_API_URL);
  console.log("EARTHOS_ENGINE_HOST", EARTHOS_ENGINE_HOST);
  console.log("EARTHOS_ENGINE_SUBDOMAINS", EARTHOS_ENGINE_SUBDOMAINS);
  console.log("Process env: ", process.env);
  console.log("------------------------------------------------------------------------------");
}

export const CONTACT_EMAIL = "info@ecosophy.is";

// TODO: These should live elsewhere
export function getRandomFormulaServer() {
  if (EARTHOS_ENGINE_HOST.indexOf("{s}") === -1) {
    return EARTHOS_ENGINE_HOST;
  }
  const subdomains = EARTHOS_ENGINE_SUBDOMAINS.split("");
  const subdomain = subdomains[Math.floor(Math.random()*subdomains.length)];
  return EARTHOS_ENGINE_HOST.replace("{s}", subdomain);
}

export function getAllFormulaServers() {
  if (EARTHOS_ENGINE_HOST.indexOf("{s}") === -1) {
    return [EARTHOS_ENGINE_HOST];
  }
  return EARTHOS_ENGINE_SUBDOMAINS.split("").map(
    (item) => EARTHOS_ENGINE_HOST.replace("{s}", item)
  );
}
