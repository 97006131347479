import { EARTHOS_API_URL } from "../constants";
import { AuthState } from "../GEMSchema";
import { useAtom, useAtomValue } from "jotai";
import { authAtom, currentAuthTypeAtom, viewerAuthAtom } from "../state";
import axios, { AxiosError, AxiosInstance } from "axios";
import { SetStateAction } from "react";

type CurrentAuthType = "user" | "viewer";

export const useCurrentAuth = (): [
  AuthState,
  React.Dispatch<React.SetStateAction<CurrentAuthType>>
] => {
  const [currentAuth, setCurrentAuth] = useAtom(currentAuthTypeAtom);
  const userAuth = useAtomValue(authAtom);
  const viewerAuth = useAtomValue(viewerAuthAtom);

  const auth = currentAuth === "user" ? userAuth : (viewerAuth as AuthState);
  return [auth, setCurrentAuth];
};

export const axiosGetStorageAuth = () => {
  const viewerAuth = JSON.parse(
    sessionStorage.getItem("es:viewer-auth") as string
  );
  if (viewerAuth?.token) {
    return viewerAuth.token;
  } else {
    const auth = JSON.parse(localStorage.getItem("es:auth") as string);
    return auth?.token;
  }
};

type AxiosErrorHandler = (err: any) => any;

// TODO: There is currently an assumption that every time we get 401, the user
// token was expired. This doesn't always need to be true, as in the User Account
// providing an incorrect password will also return a 401
export const axiosResponseRefreshToken: AxiosErrorHandler =
  (axiosInstance: AxiosInstance) => async (err: AxiosError) => {
    const originalConfig = err.config as any;

    // TODO: This condition doesn't make any sense
    if (originalConfig.url !== "/token/pair" && err.response) {
      // User Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        const rc = axios.create({
          baseURL: EARTHOS_API_URL,
          headers: {
            "Content-Type": "application/json",
          },
        });

        originalConfig._retry = true;
        const auth = JSON.parse(localStorage.getItem("es:auth") as string);

        const rs = await rc
          .post("token/refresh", {
            refresh: auth?.refresh,
          })
          .then(
            (response) => response,
            (error) => {
              localStorage.setItem(
                "es:auth",
                JSON.stringify({
                  token: "",
                  refresh: "",
                  userid: 0,
                  lastRefresh: Math.floor(Date.now() / 1000),
                })
              );
              console.log("Caught error.");
              window.location.href = "/auth/sign-in";
            }
          );

        const { access, refresh } = rs?.data;
        localStorage.setItem(
          "es:auth",
          JSON.stringify({
            token: access,
            refresh: refresh,
            userid: auth?.userid,
            lastRefresh: Math.floor(Date.now() / 1000),
          })
        );
        return axiosInstance(originalConfig);
      }
    }

    return Promise.reject(err);
  };

export const setAuthFromRefreshResponse = (
  response: any,
  setAuth: (update: SetStateAction<AuthState>) => void
) =>
  setAuth({
    token: response.access,
    refresh: response.refresh,
    userid: response.user.id,
    lastRefresh: Math.floor(Date.now() / 1000),
  });
