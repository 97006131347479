import axios from "axios";
import { EARTHOS_API_URL } from "../constants";
import {
  GEMUser,
  Project,
  DataLayer,
  ESCountry,
  DataSource,
  OrderedLayer,
  UsageScopeType,
  UsageObjectTypeType,
  UserRoleType,
  SimpleOrganizationInfo,
  ESColorScale,
  ESOrganization,
} from "../GEMSchema";
import { Probe } from "../lib/probes";
import {
  axiosGetStorageAuth,
  axiosResponseRefreshToken,
} from "../lib/authUtils";

const APIconnection = axios.create({
  baseURL: EARTHOS_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

APIconnection.interceptors.request.use(
  (config) => {
    if (!config.headers) {
      return config;
    }
    const authToken = axiosGetStorageAuth();
    if (authToken) {
      config.headers["Authorization"] = "Bearer " + authToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

APIconnection.interceptors.response.use((res) => {
  return res;
}, axiosResponseRefreshToken(APIconnection));

/**
 * The difference between AuthService and ApiService is that
 * AuthService provides Authorization headers to all its methods, irregardlessly
 * if the user is a viewer-user, email-inactive-user, non-onboarded or full user
 */
class APIService {
  async report_error(error_type: string, error_info: string) {
    return APIconnection.post("/error/", { error_type, error_info }).then(
      (response) => response.data
    );
  }

  // User Auth related

  /** Requests resending of the activation / email change message.
   *  If user is currently changing their address, it will be sent to a new one.
   *  If the user is not doing that, it will fail if user is activated. */
  async user_resend_activation_email() {
    return APIconnection.post("/user/email_resend/").then(
      (response) => response.data
    );
  }

  /**
   * Performs User Onboarding.
   * Currently this means fully activating the account with a Signup Code.
   * Eventually it will just start the tutorial
   */
  async user_complete_onboard(termsAccepted: boolean) {
    return APIconnection.post("/user/onboard/", {
      terms_accepted: termsAccepted,
    }).then((response) => response.data);
  }

  /** Changes user email by sending an activation message to the new address */
  async user_email_update(newEmail: string, password?: string) {
    return APIconnection.post("/user/email_change/", {
      new_email: newEmail,
      password,
    }).then((response) => response.data);
  }

  /** Changes user password or enables password if none was enabled before.
   *  oldPassword is required only for the password change.
   */
  async user_password_update(newPassword: string, oldPassword?: string) {
    return APIconnection.post("/user/password_change/", {
      new_password: newPassword,
      old_password: oldPassword,
    }).then((response) => response.data);
  }

  /**
   * Disables user password leaving only OAuth login
   * Will fail if no OAuth is configured for this account
   */
  async user_password_disable(password: string) {
    return APIconnection.post("/user/password_disable/", {
      password,
    });
  }

  /** Changes or activates OAuth based login for a given account */
  async user_oauth_update(idToken: string) {
    return APIconnection.post("/user/oauth_change/", { token: idToken }).then(
      (response) => response.data
    );
  }

  /**
   * Disables OAuth login, leaving only password login
   * Will fail if no password is set for this account
   */
  async user_oauth_disable(password: string) {
    return APIconnection.post("/user/oauth_disable/", {
      password,
    });
  }

  async user_update(user: Partial<GEMUser>) {
    return APIconnection.patch("/user/", user).then(
      (response) => response.data
    );
  }

  async user_delete() {
    return APIconnection.delete("/user/").then((response) => response.data);
  }

  async user_payment_add(
    organization: string,
    type: string,
    details: { [key: string]: any }
  ) {
    return APIconnection.post("/user/paymentmethod/create/", {
      organization,
      type,
      details,
    }).then((response) => response.data);
  }

  async user_payment_delete(id: string) {
    return APIconnection.post("/user/paymentmethod/delete/", { id }).then(
      (response) => response.data
    );
  }

  async user_lookup(query: string) {
    return APIconnection.get("/user/search/", { params: { query } }).then(
      (response) => response.data
    );
  }

  /**
   * Returns current Organization Info for the Organization Account page.
   * Only available to Org Admin
   *
   * Data is of type SimpleOrganizationInfo
   */
  async organization_info() {
    return APIconnection.get("/user/organization/").then(
      (response) => response.data as ESOrganization
    );
  }

  async organization_members_list() {
    return APIconnection.get("/user/organization/members/").then(
      (response) => response.data
    );
  }

  async organization_list(onlyNonDefault?: boolean) {
    return APIconnection.get("/user/organization/list/", {
      params: {
        non_default: onlyNonDefault,
      },
    }).then((response) => response.data);
  }

  async organization_get(id: string) {
    return APIconnection.get(`/user/organization/${id}/`).then(
      (response) => response.data
    );
  }

  // async organization_create(name: string, url: string, country: string) {
  //   return APIconnection.post("/user/organizations/", {
  //     name,
  //     url,
  //     country,
  //   }).then((response) => response.data);
  // }

  async usage_charts(
    scope: UsageScopeType,
    object_type: UsageObjectTypeType,
    {
      filter_only,
      date_from,
      date_to,
    }: {
      filter_only?: string;
      date_from?: string;
      date_to?: string;
    }
  ) {
    return APIconnection.get("/user/usage/charts/", {
      params: {
        scope,
        object_type,
        filter_only,
        date_from,
        date_to,
      },
    }).then((response) => response.data);
  }

  async usage_reports(
    scope: UsageScopeType,
    {
      dateFrom,
      dateTo,
      limit,
      offset,
    }: {
      dateFrom?: string;
      dateTo?: string;
      limit?: number;
      offset?: number;
    }
  ) {
    return APIconnection.get("/user/usage/reports/", {
      params: {
        scope,
        date_from: dateFrom,
        date_to: dateTo,
        limit,
        offset,
      },
    }).then((response) => response.data);
  }


  async user_events_list(user_id: string, date_from: Date, date_to: Date, event_type?: string) {
    return APIconnection.get("/user/events/", {
      params: {
        user_id,
        date_from: date_from.toISOString(),
        date_to: date_to.toISOString(),
        event_type,
      }
    }).then((response) => response.data);
  }

  async subscription_list(all: boolean = false) {
    if (all) {
      return APIconnection.get("/billing/subscription/?all=true").then(
        (response) => response.data
      );
    } else {
      return APIconnection.get("/billing/subscription/").then(
        (response) => response.data
      );
    }
  }

  async subscription_tier_list() {
    return APIconnection.get("/billing/subscription/tiers/").then(
      (response) => response.data
    );
  }

  async subscription_tier_create(settings: any) {
    return APIconnection.post("/billing/subscription/tiers/", settings).then(
      (response) => response.data
    );
  }

  async subscription_tier_delete(tier: string) {
    return APIconnection.delete(`/billing/subscription/tiers/${tier}/`).then(
      (response) => response.data
    );
  }

  async subscription_create(tier: string, period: string) {
    return APIconnection.post("/billing/subscription/", { tier, period }).then(
      (response) => response.data
    );
  }

  async subscription_cancel(id: string) {
    return APIconnection.delete(`/billing/subscription/${id}/`).then(
      (response) => response.data
    );
  }

  async transaction_list() {
    return APIconnection.get("/billing/transactions/").then(
      (response) => response.data
    );
  }

  async payment_method_create(type: string, details: { [key: string]: any }) {
    return APIconnection.post("/billing/paymentmethod/",
      { type, details }
    ).then(
      (response) => response.data,
      (error) => {
        if (error.response.status === 401) {
          return { error: "Permission denied: You are not allowed to create a payment method." };
        }
        if (error.response.status === 422) {
          return { error: "API error." };
        }
        return { error: error.response.data };
      }
    );
  }

  async payment_method_delete(id: string) {
    return APIconnection.delete(`/billing/paymentmethod/${id}/`).then(
      (response) => response.data
    );
  }


  // TODO: Add Pagination, supported by API
  async user_list({
    scope,
    filterOrgId,
    limit,
    offset,
  }: {
    scope?: "org" | "all";
    filterOrgId?: string;
    limit?: number;
    offset?: number;
  }) {
    return APIconnection.get("/user/list/", {
      params: {
        scope,
        filter_org: filterOrgId,
        limit: limit || 1000,
        offset,
      },
    }).then((response) => response.data);
  }

  /**
   * Create a user (not requiring a Signup Code), assign them an Organization
   * and send an email with the invitation link.
   *
   * Works for admins and org admins.
   *
   * @param email required, must not be assigned to an existing user
   * @param orgId required, for org admins only their own org id
   * @param makeAdmin required, true if user should be an admin, false if member
   * @param name optional, user name - can be set later
   * @returns user object
   */
  async user_invite_preregistered(
    email: string,
    orgId: string | undefined,
    makeAdmin: boolean,
    name?: string
  ) {
    return APIconnection.post("/user/invite_preregistered/", {
      email,
      name,
      make_admin: makeAdmin,
      org_id: orgId,
    }).then((response) => response.data);
  }

  /**
   * Assign user a role (admin / member) within the existing organization.
   *
   * Endpoint for org_admins.
   *
   * @param email required, email of user within the current organization.
   * @param status required, either "admin" or "member"
   * @returns user object
   */
  async user_assign_role(email: string, status: UserRoleType) {
    return APIconnection.post("/user/organization/assign_role/", {
      email,
      status,
    }).then((response) => response.data);
  }

  /**
   * Assign user a new organization and/or a role.
   *
   * Endpoint for admins.
   *
   * @param email required, email of user within the current organization.
   * @param status required, either "admin" or "member"
   * @param orgId required, ID of the new / target organization
   * @returns user object
   */
  async user_assign_organization(
    email: string,
    status: UserRoleType,
    orgId: string
  ) {
    return APIconnection.post("/user/assign_organization/", {
      email,
      status,
      org_id: orgId,
    }).then((response) => response.data);
  }

  async apikey_list() {
    return APIconnection.get("/user/apikey/").then((response) => response.data);
  }

  async apikey_details(id: string) {
    return APIconnection.get(`/user/apikey/${id}`).then(
      (response) => response.data
    );
  }

  async apikey_create(user: string, name: string) {
    return APIconnection.post("/user/apikey/", {
      user,
      name,
    }).then((response) => response.data);
  }

  async apikey_delete(id: string) {
    return APIconnection.delete(`/user/apikey/${id}`).then(
      (response) => response.data
    );
  }

  async project_list() {
    return APIconnection.get("/project/list/").then(
      (response) => response.data
    );
  }

  async project_create(name: string, description: string) {
    return APIconnection.post("/project/create/", { name, description }).then(
      (response) => response.data
    );
  }

  async project_get(id: string): Promise<Project> {
    return APIconnection.get(`/project/${id}/`).then(
      (response) => response.data
    );
  }

  // patch project spatial_boundaries
  async project_patch(id: string, patched_project: Partial<Project>) {
    return APIconnection.patch(`/project/${id}/`, patched_project).then(
      (response) => response.data
    );
  }

  async project_rename(id: string, name: string) {
    return APIconnection.patch(`/project/${id}/`, { name }).then(
      (response) => response.data
    );
  }

  /**
   * Requests a JSON of the project to be downloaded into a file
   * @param id - project ID
   * @returns a JSON with all Project data and layers
   */
  async project_export(id: string) {
    return APIconnection.post(`/project/${id}/export/`).then(
      (response) => response.data
    );
  }

  /**
   * Imports a JSON file with a Project and Layers
   * @param file a JSON file with the project data
   * @returns
   */
  async project_import(project_file: File) {
    return APIconnection.post(
      "/project/import/",
      {
        project_file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    ).then((response) => response.data);
  }

  async project_set_permissions(
    project_id: string,
    user_id: string | null,
    permission: string
  ): Promise<Project> {
    return APIconnection.put(`/project/${project_id}/permissions/`, {
      user_id,
      permission,
    }).then((response) => response.data);
  }

  async project_update_viewer_url(
    project_id: string,
    viewer_url: string
  ): Promise<Project> {
    return APIconnection.put(`/project/${project_id}/viewer_url/`, {
      viewer_url,
    }).then((response) => response.data);
  }

  async project_update_viewer_password(
    project_id: string,
    password: string
  ): Promise<Project> {
    return APIconnection.put(`/project/${project_id}/viewer_password/`, {
      password,
    }).then((response) => response.data);
  }

  async project_reorder_layers(
    project_id: string,
    layers: OrderedLayer[]
  ): Promise<Project> {
    return APIconnection.put(`/project/${project_id}/reorder_layers/`, {
      layers: layers,
    }).then((response) => response.data);
  }

  // Admin related

  async admin_statistics() {
    return APIconnection.get("/admin/statistics/").then(
      (response) => response.data
    );
  }

  /** Sends an email invitation to a pre-registered user */
  async admin_invite_activated_user(
    email: string,
    {
      name,
      orgId,
      isOrgAdmin,
    }: {
      name?: string;
      orgId?: string;
      isOrgAdmin?: boolean;
    }
  ) {
    return APIconnection.post("/user/invite_activated/", {
      email,
      name,
      org_id: orgId,
      is_org_admin: isOrgAdmin,
    }).then((response) => response.data);
  }

  // Generate Signup Codes
  async admin_generate_signup_codes(number: number, comment?: string) {
    return APIconnection.post("/admin/signup_codes/", {
      number,
      comment,
    }).then((response) => response.data);
  }

  // Layer related
  async layer_create(
    project: string,
    parent: string | null,
    name: string,
    type: string
  ) {
    const params: { [key: string]: any } = { project_id: project, name, type };
    if (parent) {
      params["parent_id"] = parent;
    }
    return APIconnection.post("/project/layer/create/", params).then(
      (response) => response.data
    );
  }

  async layer_duplicate(layerId: string) {
    return APIconnection.post(`/project/layer/${layerId}/duplicate/`).then(
      (response) => response.data
    );
  }

  async layer_get(id: string): Promise<DataLayer> {
    return APIconnection.get(`/project/layer/${id}/`).then(
      (response) => response.data
    );
  }

  async layer_delete(id: string) {
    return APIconnection.delete(`/project/layer/${id}/`).then(
      (response) => response.data
    );
  }

  async layer_update(id: string, name: string) {
    return APIconnection.put(`/project/layer/${id}/`, { name }).then(
      (response) => response.data
    );
  }

  async layer_range(id: string, min: number, max: number) {
    return APIconnection.put(`/project/layer/${id}/range/`, { min, max }).then(
      (response) => response.data
    );
  }

  async layer_rename(id: string, name: string): Promise<DataLayer> {
    return APIconnection.put(`/project/layer/${id}/rename/`, { name }).then(
      (response) => response.data
    );
  }

  async layer_description_update(
    id: string,
    description: string
  ): Promise<DataLayer> {
    return APIconnection.put(`/project/layer/${id}/description/`, {
      description,
    }).then((response) => response.data);
  }

  async layer_formula_set(id: string, formula: string) {
    return APIconnection.put(`/project/layer/${id}/formula/`, { formula }).then(
      (response) => response.data
    );
  }

  async layer_dopesheet(id: string, start: number, end: number) {
    return APIconnection.get(`/project/layer/${id}/dopesheet/`, {
      params: { start, end },
    }).then((response) => response.data);
  }

  async layer_move(id: string, newparent: string) {
    return APIconnection.put(`/project/layer/${id}/move/`, { newparent }).then(
      (response) => response.data
    );
  }

  async layer_template_list() {
    return APIconnection.get(`/project/layer/templates/`).then(
      (response) => response.data
    );
  }

  async layer_add_from_template(projectId: string, templateId: string) {
    return APIconnection.post(`/project/layer/templates/import/`, {
      project_id: projectId,
      template_id: templateId,
    }).then((response) => response.data);
  }

  async colorscale_list() {
    return APIconnection.get(`/project/colorscale/list/`).then(
      (response) => response.data
    );
  }

  async colorscale_set_from_template(id: string, scaleId: number) {
    return APIconnection.put(`/project/layer/${id}/colorscale/fromtemplate/`, {
      scale_id: scaleId,
    }).then((response) => response.data);
  }

  async colorscale_import(id: string, colorscale: string) {
    return APIconnection.put(`/project/layer/${id}/colorscale/import/`, {
      colorscale,
    }).then((response) => response.data);
  }

  async colorscale_set(id: string, colorscale: ESColorScale) {
    return APIconnection.put(`/project/layer/${id}/colorscale/`, {
      colors: colorscale.colors,
      offsets: colorscale.offsets
    }).then((response) => response.data);
  }

  async colorscale_reverse(id: string) {
    return APIconnection.put(`/project/layer/${id}/colorscale/reverse/`).then(
      (response) => response.data
    );
  }

  async update_probes(id: string, probes: Probe[]) {
    return APIconnection.put(`/project/layer/${id}/probes/`, {
      probes,
    }).then((response) => response.data);
  }

  /**
   * Returns data source information based on a list of dhashes provided.
   * The dhashes can be obtained from the Formula Engine response headers.
   */
  async dhash_info_list(dhashes: string[]) {
    return APIconnection.post(`/dataregistry/dhash/`, {
      dhashes,
    }).then((response) => response.data);
  }

  async variable_list(
    search: string | null = null,
    dataset: string | null = null
  ) {
    return APIconnection.get(`/dataregistry/var/list/`, {
      params: { search, dataset },
    }).then((response) => response.data);
  }

  async variable_info(varname: string) {
    return APIconnection.get(`/dataregistry/var/${varname}/`).then(
      (response) => response.data
    );
  }

  async dataset_list() {
    return APIconnection.get("/dataregistry/dataset/").then(
      (response) => response.data
    );
  }

  async dataset_get(id: number | string) {
    return APIconnection.get(`/dataregistry/dataset/${id}/`).then(
      (response) => response.data
    );
  }

  async dataset_update(id: number, settings: DataSource) {
    return APIconnection.put(`/dataregistry/dataset/${id}/`, settings).then(
      (response) => response.data
    );
  }

  async datavariable_update(varname: string, settings: any) {
    return APIconnection.put(`/dataregistry/var/${varname}/`, settings).then(
      (response) => response.data
    );
  }

  async workorder_list(type: string, status: string, namespace: string) {
    return APIconnection.get("/dataregistry/workorder/status/", {
      params: { type, status, namespace }
    }).then((response) => response.data);
  }

  async entities_get(
    layer: number,
    lat: number,
    lon: number,
    alt: number,
    range: number,
    filter_: any
  ) {
    const filter = JSON.stringify(filter_);
    return APIconnection.get("/dataregistry/entities/", {
      params: { layer, lat, lon, alt, range, filter },
    }).then((response) => response.data);
  }

  async search(q: string) {
    return APIconnection.get("/search/", { params: { q } }).then(
      (response) => response.data
    );
  }

  async search_countries_list(): Promise<ESCountry[]> {
    return APIconnection.get("/search/list/countries/").then(
      (response) => response.data
    );
  }

  async geometry_dataset_list() {
    return APIconnection.get("/dataregistry/geometry/").then(
      (response) => response.data
    );
  }

  async geometry_dataset_get(id: string) {
    return APIconnection.get(`/dataregistry/geometry/${id}`).then(
      (response) => response.data
    );
  }

  async geometry_dataset_add(
    name: string, 
    description: string, 
    time_start: Date, 
    time_end: Date, 
    freq_update: string, 
    url: string, 
    doi: string, 
    license: string, 
    license_class: string, 
    created_by: string, 
    geometry: File
  ) {
    var formData = new FormData();
    formData.append("body", JSON.stringify({
      name, description, time_start, time_end,
      freq_update, url, doi, license, license_class,
      created_by
    }))
    formData.append("geometry", geometry);

    return APIconnection.post(`/dataregistry/geometry/`, 
      formData,
      { 
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }


  async project_add_layer_from_geometry_dataset(project_id: string, template_id: string) {
    return APIconnection.post(`/project/layer/geometry/import/`,
      { project_id, template_id }
    ).then(
      (response) => response.data
    );
  }

  async layer_geometry_update(layer_id: string, geometry: object) {
    return APIconnection.put(`/project/layer/${layer_id}/geometry/`, { geometry }).then(
      (response) => response.data
    );
  }

  async layer_geometry_get(layer_id: string) {
    return APIconnection.get(`/project/layer/${layer_id}/geometry/`).then(
      (response) => response.data
    );
  }



  // Statistics in admin
  async admin_statistics_apicalls() {
    return APIconnection.get("/admin/statistics/apicalls/").then(
      (response) => response.data
    );
  }

  async admin_statistics_userevents() {
    return APIconnection.get("/admin/statistics/userevents/").then(
      (response) => response.data
    );
  }


}

const APIServiceInstance = new APIService();

export default APIServiceInstance;
