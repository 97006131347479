import { atom, useAtom, WritableAtom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { atomsWithQuery } from "jotai-tanstack-query";
import { Viewer, Entity as CesiumEntity } from "cesium";

import { GEMUser, AuthState, DataLayer, Project, ESColorScale } from "../GEMSchema";
import { HoverCoordinatesType } from "../lib/mapExtensions/coordinates";
import { Probe } from "../lib/probes";
import AuthService from "../services/authService";
import { useTranslation } from "react-i18next";
import { showPromiseToast } from "../components/Toasts";
import APIService from "../services/apiService";

const initialAuthState: AuthState = {
  token: null,
  refresh: null,
  lastRefresh: null,
  userid: null,
};

export type MapTool = 
  "None"            |
  "ValuePicker"     |
  "SplitMap"        |
  "CreateProbe"     |
  "CreatePin"       |
  "MeasureDistance" |
  "MeasureArea"     |
  "DrawPolygon"     |
  "Draw"            ;

export type MapToolHandler = {
  // Callbacks for activation/deactivation
  onActivate?: (viewer: Viewer, mapTool: any) => void;
  onReset?: (viewer: Viewer, mapTool: any) => void;

  // Callback for clicks on the map
  onMapClick: (viewer: Viewer, event: any, mapTool: any) => any;

  getIcon?: () => string;
  getCursor?: () => string;
  isEnabled?: () => boolean;

  // React component to render when active.
  Display: (props: { viewer: Viewer }) => React.ReactElement | null;
}

export type MapToolState = {
  tool: MapTool;
  points: Array<any>;
  markers: Array<any>;
  line: any;
};

export type DisplayedColorScale = Omit<ESColorScale, "id" | "name">;

export const initialMapToolState: MapToolState = {
  tool: "None",
  points: [],
  markers: [],
  line: undefined,
};

export const authAtom = atomWithStorage<AuthState>(
  "es:auth",
  initialAuthState
);

export const viewerAuthAtom = atomWithStorage<AuthState | null>(
  "es:viewer-auth",
  null,
  createJSONStorage(() => sessionStorage)
);

export const [userAtom] = atomsWithQuery<GEMUser>((get) => ({
  queryKey: ["user", get(authAtom).userid],
  queryFn: () => {
    const auth = get(authAtom);
    if (auth.userid) {
      return AuthService.user_refresh();
    } else {
      return { id: null } as GEMUser;
    }
  },
  refetchOnMount: true,
  refetchOnWindowFocus: true,
  refetchInterval: 60000, // Update at least once per minute
}));

/*
 * TODO: Currently, we have separate atoms for entity layers, current tile layer,
 *       selected layers, available layers, probes, minimized probes, selected map entity...
 *       This is WAY too complex. We need to keep this bounded.
 *       Perhaps replace this with some kind of layer manager?
 */

export const snapTime = 60 * 5; // Five minutes, for now. We might reduce this later.

export const currentAuthTypeAtom  = atom<"user" | "viewer">("user");
export const timeAtom             = atom<number>(Math.trunc(Math.round(Date.now() / 1000.0 / snapTime) * snapTime)); // Unix timestamp, seconds.
export const projectIdAtom        = atom<string | null>(null);
export const projectAtom          = atom<Project | null>(null);
export const mapToolAtom          = atom<MapToolState>(initialMapToolState);
export const entityLayerIdsAtom   = atom<string[]>([]);
export const visibleLayersIdsAtom = atom<string[]>([]);
export const availableLayersAtom  = atom<DataLayer[]>([]);
export const cesiumViewerAtom     = atom<Viewer | null>(null);
export const colorScaleAtom       = atom<DisplayedColorScale | null>(null);
export const visibleProbesAtom    = atom<Probe[]>([]);
export const minimizedProbeLayerIdsAtom = atom<string[]>([]);
export const selectedMapEntityAtom = atom<CesiumEntity | null>(null);

export const mapCoordinatesAtom   = atom<HoverCoordinatesType>([NaN, NaN]);
export const mapCoordinatesLiveAtom = atom<boolean>(true);

export const tilesLoadingAtom = atom<[number,number,number]>([0,0,0]);

export const dialogStateAtom = atom<{
  [key: string]: WritableAtom<boolean, any, any>;
}>({
  project_new: atom(false),
  project_open: atom(false),
  project_rename: atom(false),
  quick_start: atom(false),
  geometry_from_dataset: atom(false),
  alert_new: atom(false),
  colorscale_load_from_template: atom(false),
  colorscale_import: atom(false),
  colorscale_editor: atom(false),
  formula_editor: atom(false),
  intro_tour: atom(false),
});

export const useDialogState = (item: string) => {
  const [state] = useAtom(dialogStateAtom);
  return state[item];
};

export const baseLayersAtom = atom<{
  [key: string]: boolean;
}>({
  drainshade: true,
  landmask: false,
  labels: true,
});

// export const colorScaleAtom = atom<GEMColorScale>();

export function useUserSettings() {
  const [user, userDispatch] = useAtom(userAtom);
  const { t } = useTranslation();

  return {
    set: function (key: string, value: string | boolean | number) {
      const updatedValue = { settings: {[key]: value} };
      const updatePromise = APIService.user_update(updatedValue);
      const loadingMessage = t("ui.notifications.loading", {
        field: t(`user_settings.settings.${key}`),
      });
      const successMessage = t("ui.notifications.saved", {
        field: t(`user_settings.settings.${key}`),
      });
      const errorMessage = t("ui.notifications.error", {
        field: t(`user_settings.settings.${key}`),
      });
      showPromiseToast(updatePromise, loadingMessage, successMessage, errorMessage);
      updatePromise.then(
        (data) => {
          userDispatch({ type: "refetch" });
          console.log(user);
        },
        (error) => {
          // TODO: Report error
        }
      );
      return updatePromise;
    },
    getString: function (key: string, defaultValue?: string): string {
      if (user?.settings && key in user.settings) {
        return user.settings[key].toString();
      }
      if (defaultValue !== undefined) {
        return defaultValue;
      }
      return "";
    },
    getBoolean: function (key: string, defaultValue?: boolean): boolean {
      if (user?.settings && key in user.settings) {
        const type = typeof user.settings[key];
        const value = user.settings[key];
        if (type === 'string') {
          return value === 'true' || !!+value;  // here we parse to number first
        }
        return !!(user.settings[key] as boolean);
      }
      return (defaultValue !== undefined) ? defaultValue : false;
    },
    getNumber: function (key: string, defaultValue?: number): number {
      if (user?.settings && key in user.settings) {
        return user.settings[key] as number;
      }
      return defaultValue || 0;
    },
    get: function (key: string, defaultValue?: string | boolean | number): string | boolean | number {
      if (user?.settings && key in user.settings) {
        return user.settings[key];
      }
      return defaultValue || "";
    }
  }
}
