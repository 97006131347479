import React, { useEffect, useMemo } from "react";
import { usePage } from "./usePage";

interface PageProps {
  children: JSX.Element | JSX.Element[];
}

const Page: React.FC<PageProps> = ({ children }) => {
  const { onLoad } = usePage();

  const render = useMemo(() => {
    return <>{children}</>;
  }, [children]);

  useEffect(() => {
    onLoad(render);
  }, [onLoad, render]);

  return render;
};

export default Page;
