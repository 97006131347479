import { Link } from "react-router-dom";
import { Button } from "@blueprintjs/core";

const Page500 = () => (
  <div className="auth-content-body">
    <div className="container">
      <h1>500: Internal server error.</h1>
      <p>
        The server encountered something unexpected that didn't allow it to
        complete the request.
      </p>
    </div>
    <Link to="/" className="footer-button">
      <Button>Return to website</Button>
    </Link>
  </div>
);

export default Page500;
