import { Link } from "react-router-dom";
import { Button } from "@blueprintjs/core";

const Page403 = () => (
  <div className="auth-content-body">
    <div className="container">
      <h1>403: Access Denied</h1>
      <p>
        You are trying to access a resource that you do not have sufficient
        permissions to access.
      </p>
    </div>

    <Link to="/" className="footer-button">
      <Button>Return to the site</Button>
    </Link>
  </div>
);

export default Page403;
