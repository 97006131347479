import EarthOSLogoDark from "../assets/img/icons/logo_white_writing.png";

export const DesktopOnlyWrapper = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <>
    <div className="layout-desktop">{children}</div>
    <div className="layout-mobile">
      <div className="mobile-info-container">
        <img src={EarthOSLogoDark} alt="EarthOS" className="mobile-logo" />
        <p>EarthOS currently requires a minimum resolution of 800 pixels width.</p>
        <p>If you're on a mobile device, try rotating it.</p>
      </div>
    </div>
  </>
);

export default DesktopOnlyWrapper;
