import axios from "axios";
import { EARTHOS_API_URL } from "../constants";
import { axiosResponseRefreshToken } from "../lib/authUtils";

const AuthConnection = axios.create({
  baseURL: EARTHOS_API_URL,
});

/**
 * The difference between AuthService and ApiService is that
 * AuthService doesn't provide JWT Authorization headers to its methods
 * therefore handling most of the logged-out or not-yet registered app states
 */
class AuthService {
  /** Login and registration through OAuth (Google) */
  async oauth(idToken: string) {
    return AuthConnection.post("/user/oauth/", {
      token: idToken,
    }).then((response) => response.data);
  }

  /** In case of admin pre-registered users, activates them with OAuth */
  async activate_with_oauth(activationKey: string, idToken: string) {
    return AuthConnection.post("/user/activate_with_oauth/", {
      activation_key: activationKey,
      token: idToken,
    }).then((response) => response.data);
  }

  /** Password-based registration */
  async register(email: string, password: string, name: string) {
    return AuthConnection.post("/user/register/", {
      email,
      password,
      name,
    }).then((response) => response.data);
  }

  /** Password-based login */
  async login(email: string, password: string) {
    return AuthConnection.post("/user/login/", {
      email,
      password,
    }).then((response) => response.data);
  }

  /** Confirms / activates email address for password-based users */
  async confirm_email(activationKey: string) {
    return AuthConnection.post("/user/email_confirm/", {
      activation_key: activationKey,
    }).then((response) => response.data);
  }

  /** Requests password reset / forgot password */
  async request_password_reset(email: string) {
    return AuthConnection.post("/user/password_forgot/", {
      email,
    }).then((response) => response.data);
  }

  /** Performs password reset with an email token */
  async perform_password_reset(resetKey: string, newPassword: string) {
    return AuthConnection.post("/user/password_reset/", {
      reset_key: resetKey,
      new_password: newPassword,
    }).then((response) => response.data);
  }

  /** Request viewer-only user token for viewing projects in read-only mode */
  async getViewerToken(viewerKey: string, password?: string) {
    return AuthConnection.post("/token/viewer/", {
      viewer_url: viewerKey,
      password,
    });
  }

  /**
   * Refreshes the non-viewer-user session token
   * Forces non-viewer-user-auth - otherwise it could be in ApiService
   */
  async user_refresh() {
    const auth = JSON.parse(localStorage.getItem("es:auth") as string);
    return AuthConnection.get("/user/", {
      headers: {
        Authorization: "Bearer " + auth?.token,
      },
    }).then(
      (response) => response.data,
      axiosResponseRefreshToken(AuthConnection)
    );
  }
}

const AuthServiceInstance = new AuthService();

export default AuthServiceInstance;
