import { Component } from "react";
import { Button, Collapse } from "@blueprintjs/core";
import APIService from "../services/apiService";
import AuthLayout from "../layouts/AuthLayout";

type ErrorState = {
  hasError: boolean;
  errorType: any;
  errorInfo: any;
  showErrors: boolean;
};

class ErrorBoundary extends Component<any, ErrorState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      errorType: null,
      errorInfo: null,
      showErrors: false,
    };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(errorType: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.setState({ errorType, errorInfo });
    try {
      APIService.report_error(errorType.toString(), JSON.stringify(errorInfo));
    } catch (error) {
      console.log("Error reporting failed.");
    }
  }

  private handleClick = () => {
    this.setState({ showErrors: !this.state.showErrors });
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <AuthLayout intent="error">
          <div className="es-error-boundary">
            <h2>Error!</h2>

            <p>We apologize for the inconvenience.</p>
            <p>An error has occurred in the system.</p>
            <p>Our team has been notified and we have logged the issue.</p>
            <p>Unfortunately, it cannot be recovered at this time.</p>
            <p>We are working to resolve the issue as soon as possible.</p>
            <p>In the meantime, try refreshing the page.</p>

            <Button onClick={this.handleClick}>
              {this.state.showErrors ? "Hide" : "Show"} error details
            </Button>
            <Collapse isOpen={this.state.showErrors}>
              <h3>Guru meditation</h3>
              Error: {this.state.errorType && this.state.errorType.toString()}
              <br />
              <br />
              Details:{" "}
              <pre>
                {this.state.errorInfo &&
                  JSON.stringify(this.state.errorInfo).replaceAll("\\n", "\n")}
              </pre>
            </Collapse>
          </div>
        </AuthLayout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
