import { toast, Toaster } from "react-hot-toast";

export const showPromiseToast = (
  promise: Promise<any>,
  loadingText: string,
  successText: string,
  errorText: string
) =>
  toast.promise(promise, {
    loading: loadingText,
    success: successText,
    error: errorText,
  });

export const showSuccessToast = (text: string, duration?: number) =>
  toast.success(text, { duration });

export const showErrorToast = (errorText: string, duration?: number) =>
  toast.error(errorText, { duration });

export function showCustomToast(body: React.ReactElement, duration?: number) {
  toast.custom((t) => body, { duration });
}

export const StyledToaster = () => (
  <Toaster
    containerClassName="setting-toast-container"
    toastOptions={{
      position: "top-right",
      className: "settings-toast styled-toast",
      success: {
        iconTheme: {
          primary: "#28a745",
          secondary: "black",
        },
        duration: 1500,
      },
      error: {
        iconTheme: {
          primary: "#dc3545",
          secondary: "black",
        },
        duration: 3000,
      },
    }}
  />
);
