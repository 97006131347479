import { Link } from "react-router-dom";
import { Button } from "@blueprintjs/core";

const Page404 = () => (
  <div className="auth-content-body">
    <div className="container">
      <h1>404: Not found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
    <Link to="/" className="footer-button">
      <Button>Return to the site</Button>
    </Link>
  </div>
);

export default Page404;
