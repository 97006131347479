import { Link, Outlet } from "react-router-dom";
import Page from "../routes/lazyWrappers/Page";

import logo_icon from "../assets/img/brands/ECOSOPHY-logo-02.png";
import { ReactComponent as EarthOSLogo } from "../assets/img/brands/EarthOS.svg";
import forest from "../assets/img/photos/forest.png";
import volcano from "../assets/img/photos/eyjafjallajokull.jpg";
import { Icon, Tooltip } from "@blueprintjs/core";
import { useAtomValue } from "jotai";
import { authAtom } from "../state";
import DesktopOnlyWrapper from "./DesktopOnlyWrapper";

type IntentType = "success" | "error";

const AuthLayout = ({
  intent,
  children,
}: {
  intent: IntentType;
  children?: JSX.Element;
}) => {
  const auth = useAtomValue(authAtom);
  const backgroundImage = intent === "success" ? forest : volcano;
  const extraClass = intent === "error" ? "left-pane" : "";
  const InternalComponent = children ? children : <Outlet />;
  const shouldShowSignOutButton = !!auth?.userid;
  return (
    <Page>
      <DesktopOnlyWrapper>
        <div
          className="auth-background"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className={`auth-content-pane ${extraClass}`}>
            {shouldShowSignOutButton && (
              <div className="sign-out-button-container">
                <Tooltip content="Sign Out">
                  <Link to="/auth/sign-out">
                    <Icon icon="log-out" />
                  </Link>
                </Tooltip>
              </div>
            )}
            <img src={logo_icon} alt="Logo" className="auth-logo-icon" />
            <EarthOSLogo className="auth-logo-text" />
            {InternalComponent}
          </div>
        </div>
      </DesktopOnlyWrapper>
    </Page>
  );
};

export default AuthLayout;
